import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PropTypes from "prop-types";
import "github-markdown-css/github-markdown.css";

export default function Template({ data }) {
    const { markdownRemark } = data;
    const { frontmatter, html } = markdownRemark;
    const { allMarkdownRemark } = data;
    const posts = allMarkdownRemark.edges.map((x) => x.node);
    return (
        <Layout>
            <SEO title="Home" />
            <section className="hero is-light">
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <h1 className="title alternative is-2">GalaxyWorks Legal Portal</h1>
                    </div>
                </div>
            </section>
            <section className="p-6">
                <div className="columns">
                    <div className="column is-one-third">
                        {posts.map((legalData) => (
                            <div key={legalData.frontmatter.slug} className="container">
                                <div className="columns is-vcentered">
                                    <div className="column">
                                        <Link key={legalData.frontmatter.slug} to={legalData.frontmatter.slug}>
                                            <div className="card-body">
                                                <div className="content p-sm">
                                                    {legalData.frontmatter.slug == frontmatter.slug && (
                                                        <div className="title has-text-dark is-5">
                                                            <div style={{ "text-decoration": "underline" }}>
                                                                {legalData.frontmatter.title}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {legalData.frontmatter.slug != frontmatter.slug && (
                                                        <div className="title has-text-dark is-5">
                                                            {legalData.frontmatter.title}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="column">
                        <p className="mb-2">
                            <small>Last updated: {frontmatter.date}</small>
                        </p>
                        {frontmatter.author && <div>by {frontmatter.author}</div>}
                        <p className="title">{frontmatter.title}</p>
                        <p className="subtitle mt-2">{frontmatter.description}</p>
                        <p className="markdown-body" dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                </div>
            </section>
        </Layout>
    );
}

Template.propTypes = {
    data: PropTypes.object,
};

export const pageQuery = graphql`
    query ($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                date(formatString: "MMMM, YYYY")
                slug
                title
                description
                author
                subtitle
            }
        }
        allMarkdownRemark(filter: { frontmatter: { kind: { eq: "legal" } } }) {
            edges {
                node {
                    excerpt
                    frontmatter {
                        title
                        description
                        slug
                        kind
                    }
                }
            }
        }
    }
`;
